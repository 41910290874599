import React from 'react';
import { Grid, Typography, Button, Box, Hidden } from '@mui/material';
import emailCollage from '../images/emailcollage.jpg';
import useStyles from '../styles/styles';

const Hero = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
      <Hidden smUp>
      <Grid item xs={12} sm={6} md={6}>
        <img src={emailCollage} alt="Two screenshots of email newsletters arranged on top of a light pink circle." className={classes.medImage} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="h1" fontWeight={600} className={classes.title}>
            Sarah Broat
          </Typography>
          <Typography variant="h2" fontWeight={400} className={classes.title}>
            UX Designer &amp; Developer
          </Typography>
          <Typography variant="h3" className={classes.subtitle}>
            HTML email &amp; accessibility nerd
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px' }}
            disableElevation
            href="https://www.linkedin.com/in/sbroat/"
          >
            Connect with Me
          </Button>
        </Grid>
        </Hidden>

        <Hidden smDown>
        <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h1" fontWeight={600} className={classes.title}>
              Sarah Broat
            </Typography>
            <Typography variant="h2" fontWeight={400} className={classes.title}>
              UX Designer &amp; Developer
            </Typography>
            <Typography variant="h3" className={classes.subtitle}>
              HTML email &amp; accessibility nerd
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '200px', fontSize: '16px' }}
              disableElevation
              href="https://www.linkedin.com/in/sbroat/"
            >
              Connect with Me
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
        <img src={emailCollage} alt="Two screenshots of email newsletters arranged on top of a light pink circle." className={classes.medImage} />
        </Grid>
        </Hidden>
        
      </Grid>
    </Box>
  );
};

export default Hero;