import React from 'react';
import { Box, Typography} from '@mui/material';
import useStyles from '../styles/styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      <Typography className={classes.footerText}>
        &copy; Sarah Broat 2022
      </Typography>
    </Box>
  );
};

export default Footer;