import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import sarahHeadshot from '../images/SarahHeadshot.jpg';
import useStyles from '../styles/styles';

const AboutUs = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>

        <Grid item xs={12} sm={4}>
          <img src={sarahHeadshot} alt="Heashot of Sarah, a white woman with long, curly brown hair, on top of a light pink circle." className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Hi, I'm Sarah!
          </Typography>
          <Typography fontWeight={300} vvariant="body1" gutterBottom paragraph>
            I'm a UX designer and developer based in <strong>Omaha, Nebraska</strong>. 
            </Typography>
            <Typography fontWeight={300} vvariant="body1" gutterBottom paragraph> 
            The main focus of my work is HTML email, but I also create landing pages, social media graphics, and data visualizations. I have a bachelor of science in web development, and started learning how to code as a hobby 20 years ago.
            </Typography>
            <Typography fontWeight={300} vvariant="body1" gutterBottom paragraph>
            When I'm not working, I love to knit, read nonfiction, and grow succulents.
            </Typography>
            <Typography fontWeight={300} vvariant="body1" gutterBottom paragraph>
            I'm not taking on any freelance or volunteer work currently, but in the future I may be interested in pursuing these opportunities.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;