import { makeStyles } from '@mui/styles';

const styles = (theme) => {
  return {
    toolBar: {
      height: '10vh',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      backgroundColor: 'white',
    },
    link: {
      color: "#000"
    },
    gridContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1300px',
      padding: '1.5rem 2rem',
    },
    aboutUsContainer: {
      width: '100%',
      display: 'flex',
      minHeight: '400px',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '1.5rem 0px 2rem 0px',
    },
    title: {
      paddingBottom: '15px',
      fontSize: '3.5rem',
      lineHeight: '5rem',
    },
    subtitle: {
      opacity: '0.4',
      paddingBottom: '1.5rem',
    },
    largeImage: {
      width: '100%',
      display: 'block',
      margin: '0 auto',
    },
    medImage: {
      width: '80%',
      display: 'block',
      margin: '0 auto',
    },
    footerContainer: {
      display: 'flex',
      alignItems: 'center',
      miHeight: '10vh',
      padding: '20px',
      justifyContent: 'center',
      backgroundColor: '#FCEDE5',
      flexDirection: 'column',
    },
    footerText: {
      paddingBottom: '0.5rem',
    },
  };
};

const useStyles = makeStyles(styles);
export default useStyles;