import Hero from "./components/Hero"
//import Section from "./components/Section"
//import Testimonial from "./components/Testimonial"
import Footer from "./components/Footer"
import AboutUs from "./components/AboutUs"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';
//import { teal } from '@mui/material/colors/';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '3rem',
      paddingBottom: '0',
    },
    h2: {
      fontSize: '2rem',
      lineHeight: '3.5rem',
      paddingBottom: '0',
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '2.75rem',
      opacity: '1',
      fontWeight: '300',
      paddingBottom: '20px',
    },
  },
  palette: {
    primary: {
      main: '#EFA076',
    },
  },
});

function App() {
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Hero />
      <AboutUs />
      <Footer />
      </ThemeProvider>
    </>

  );
}

export default App;
